.block {
  margin: $full-spacing auto;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * Site Title / Description
 */
.block-profile {
  .name {
    font-family: $heading-font-family;
    font-size: modular-scale(4);
    font-weight: bold;
    line-height: 1em;
    margin-bottom: $half-spacing;
    text-align: center;
    a:hover {
      text-decoration: none;
    }
  }
  .title {
    color: $light-gray;
    font-size: modular-scale(1);
    font-weight: normal;
    line-height: 1em;
    text-align: center;
  }
  .description {
    margin: $full-spacing 0;
  }
}

/**
 * Social Media Icons
 */
.block-social {
  display: table;
  margin: $full-spacing auto;
  a {
    display: block;
    float: left;
    font-size: modular-scale(3);
    line-height: 1em;
    margin: 0 $half-spacing;
  }
}

/**
 * Navigation Menu
 */
.block-navigation {
  text-align: center;
  .bullet {
    font-size: modular-scale(2);
    line-height: modular-scale(1);
    vertical-align: middle;
  }
}

/**
 * Copyright Message
 */
.block-copyright {
  font-size: modular-scale(-1);
}
