@import url('https://fonts.googleapis.com/css?family=Bitter|Raleway');

// @see http://bourbon.io/docs/#modular-scale
$modular-scale-ratio: $golden;
$modular-scale-base: 1.5em;

// Breakpoints.
$min-width-sm: 576px;
$min-width-md: 768px;
$min-width-lg: 992px;
$min-width-xl: 1280px;

$max-width-xs: $min-width-sm - 1;
$max-width-sm: $min-width-md - 1;
$max-width-md: $min-width-lg - 1;
$max-width-lg: $min-width-xl - 1;

// Typography
$base-font-family: 'Raleway', sans-serif;
$heading-font-family: 'Bitter', serif;

// Font Sizes
$base-font-size: 18px;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$full-spacing: 30px;
$half-spacing: $full-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #1565c0;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;
$white: #fff;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $blue;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #f8f8f8;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;

// Grid
$neat-grid: (
  columns: 12,
  gutter: $full-spacing,
);
