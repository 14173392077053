.posts {
  & > article {
    margin: $full-spacing 0;
  }
  & > :first-child {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
}

.content-wrapper {
  position: relative;
}

.page.container {
  padding-left: 0;
  padding-right: 0;
}
.page,
article.post {
  @include grid-container;
  padding-left: 0;
  padding-right: 0;

  .content-header {
    @include grid-column;
    margin-bottom: $half-spacing;
    .content-title {
      margin-bottom: 0;
    }
  }

  .content-body {
    @include grid-column;
  }

  .content-footer {
    @include grid-column;
    .content-footer-inner {
      @include grid-collapse;
      .content-links,
      .content-share,
      .content-tags {
        @include grid-column(6);
      }
      .content-share {
        a {
          display: inline-block;
          padding: 0 0.2em;
        }
      }
      .content-tags {
        text-align: right;
      }
    }
  }

  .content-separator {
    @include grid-column;
  }

  .screenshot {
    border: 1px solid $light-gray;
    width: 25%;
  }
}

#disqus_thread {
  margin-top: $full-spacing;
}

.container {
  background-color: $white;
  border: 1px solid $light-gray;
  box-shadow: 0 0 $half-spacing $light-gray;
  padding: $full-spacing;
}

.project-list-wrapper {
  @include grid-collapse;
}
.project-list {
  @include grid-container;
  .project-wrapper {
    @include grid-column;
    margin-bottom: $full-spacing;
    @media only screen and (min-width: $min-width-lg) {
      @include grid-column(6);
    }
  }
}

.project {
  .project-screenshot {
    img {
      border: 1px solid $light-gray;
    }
  }
  .project-title {
    font-weight: bold;
    text-align: center;
  }
  .project-description {
  }
  .project-tags {
    font-size: modular-scale(-1);
    text-align: right;
    .project-tag {
      background-color: $blue;
      color: $white;
      padding: 5px 10px;
    }
  }
}

/**
 * Back to top button.
 */
.back-to-top-wrapper {
  // Anchor to right side of content div.
  margin-left: 100%;
}
.back-to-top-link {
  cursor: pointer;
  display: none;
  position: fixed;
  bottom: 0;
  // Move left by object width + half-gutter.
  margin-left: 0 - 48px - $half-spacing;
  background-color: $dark-gray;
  color: $white;
  line-height: 1em;
  padding: $half-spacing;
}

