.sidebar {
  background-color: $dark-gray;
  color: $medium-gray;
  padding: $gutter-width;

  a {
    color: $white;
    &:hover {
      color: $blue;
    }
  }

  @media only screen and (min-width: $min-width-md) {
    .anchor-middle,
    .anchor-bottom {
      position: absolute;
      left: $gutter-width;
      right: $gutter-width; 
    }
    .anchor-top {
      top: $gutter-width;
    }
    .anchor-middle {
      top: 50%;
      transform: translateY(-50%);
    }
    .anchor-bottom {
      bottom: $gutter-width;
    }
  }
}
