$gutter-width: $full-spacing;
$max-content-width: 920px;

body {
  background-color: #f0f0f0;
  margin: 0;
}

.content-wrapper {
  max-width: $max-content-width;
  padding: $gutter-width;
}

@media only screen and (max-width: $max-width-sm) {
  .sidebar .anchor-middle {
    margin-bottom: $full-spacing;
  }
}

@media only screen and (min-width: $min-width-md) {
  $sidebar-width: 240px;
  .sidebar {
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    width: $sidebar-width - ($gutter-width * 2);
  }
  .content-wrapper {
    margin-left: $sidebar-width;
  }
}

@media only screen and (min-width: $min-width-lg) {
  $sidebar-width: 300px;
  .sidebar {
    width: $sidebar-width - ($gutter-width * 2);
  }
  .content-wrapper {
    margin-left: $sidebar-width;
  }
}
