body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $half-spacing;
}
h1 {
  font-size: modular-scale(4);
}
h2 {
  font-size: modular-scale(3);
}
h3 {
  font-size: modular-scale(2);
}

p {
  margin: 0 0 $half-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    // color: shade($action-color, 25%);
    text-decoration: underline;
  }
}

hr {
  border: 1px solid $grey-color-light;
  border-width: 1px 0 0;
  margin: 0 0 $half-spacing;
}

.page {
  h1, h2, h3 {
    @include clearfix;
  }
  h1 {
    font-size: modular-scale(3);
  }
  h2 {
    font-size: modular-scale(2);
  }
  h3 {
    font-size: modular-scale(1);
  }
}
